import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Plan() {

    const [isPay, setIsPay] = useState('IMPS');
    const [isConfirm, setIsConfirm] = useState('');

    return (
        <div>
            <div className='inner-banner'>

            </div>

            <div class="contact-form-style-one contact-form-style-one-section" style={{ boxShadow: 'none' }}>
                <h2 class="title mb-5">Activate Process</h2>
                <div className='row mb-5'>
                    <div className='col-lg-4'>
                        <div class="pricing-style-two active" onClick={() => {setIsPay('IMPS'); setIsConfirm(null)}}>
                            {
                                isPay == 'IMPS' ? <span><i class="fa fa-check"></i></span> : null
                            }

                            <div class="pricing-header">
                                <h4>IMPS/NEFT/RTGS</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div class="pricing-style-two active" onClick={() => {setIsPay('Gpay'); setIsConfirm(null)}}>
                            {
                                isPay == 'Gpay' ? <span><i class="fa fa-check"></i></span> : null
                            }
                            <div class="pricing-header">
                                <h4>Gpay/Phone Pay</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isPay == 'IMPS' ?

                        <div className='conditions_modal'>
                            <h2 class="title mb-5">Terms & Conditions</h2>
                            <ul>
                                <li>Company will provide full work load in single time.</li>
                                <li>Cust has to work according as per demo video.</li>
                                <li>Deduction applies for only incomplete work or on time.</li>
                                <li>10% Deduction for submission delay.</li>
                                <li>Pending Pages amount will be deduct in full work not submitted.</li>
                                <li>5,000 Handling Charges will be applied.</li>
                                <li>Cust has to courier complete books to company in every Monday with fast service.</li>
                                <li>Company will charge 10% Service Charge on every Billing.</li>
                                <li>If TDX will apply then deduct by company.</li>
                                <li>If cust don’t have GST number then company will not pay GST amount to cust.</li>
                                <li>If company will not give work/payment in any circumstances then will refund full amount within 7 working days.</li>
                                <li>If cust want then he/she can upgrade his/her Power Money process in Intermediate or Advanced with use money from his/her wallet.</li>
                                <li>If cust will outsource Power Money Process to anyone then his/her will get smart incentive as per incentive sheet. Incentive will be credit to his/her wallet.</li>
                                <li>Cust can be withdraw money from wallet. But its will be credited his/her bank account or Gpay/phone pay on every Tuesday/Thursday/Saturday Only.</li>
                            </ul>
                            <br />
                            <button type="button" onClick={() => { setIsConfirm(1); setIsPay(null) }}>
                                <i class="fa fa-paper-plane"></i> Agree
                            </button>
                        </div>
                        :
                        null
                }
                {
                    isPay == 'Gpay' ?

                        <div className='conditions_modal'>
                            <h2 class="title mb-5">Terms & Conditions</h2>
                            <ul>
                                <li>Company will provide full work load in single time.</li>
                                <li>Cust has to work according as per demo video.</li>
                                <li>Deduction applies for only incomplete work or on time.</li>
                                <li>10% Deduction for submission delay.</li>
                                <li>Pending Pages amount will be deduct in full work not submitted.</li>
                                <li>5,000 Handling Charges will be applied.</li>
                                <li>Cust has to courier complete books to company in every Monday with fast service.</li>
                                <li>Company will charge 10% Service Charge on every Billing.</li>
                                <li>If TDX will apply then deduct by company.</li>
                                <li>If cust don’t have GST number then company will not pay GST amount to cust.</li>
                                <li>If company will not give work/payment in any circumstances then will refund full amount within 7 working days.</li>
                                <li>If cust want then he/she can upgrade his/her Power Money process in Intermediate or Advanced with use money from his/her wallet.</li>
                                <li>If cust will outsource Power Money Process to anyone then his/her will get smart incentive as per incentive sheet. Incentive will be credit to his/her wallet.</li>
                                <li>Cust can be withdraw money from wallet. But its will be credited his/her bank account or Gpay/phone pay on every Tuesday/Thursday/Saturday Only.</li>
                            </ul>
                            <br />
                            <button type="button" onClick={() => { setIsConfirm(2); setIsPay(null) }}>
                                <i class="fa fa-paper-plane"></i> Agree
                            </button>
                        </div>
                        :
                        null

                }

                {
                    isConfirm == 1 ?
                        <AvForm method="POST" class=" contact-form contact-form">
                            <div class="row">
                                <h2 class="title mb-5">IMPS/NEFT/RTGS</h2>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="cName" label="Name" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="cNumber" label="Account Number" required />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="IFSCCode" label="IFSC Code" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="Bank" label="Bank" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">

                                    <AvCheckboxGroup inline name="checkboxExample2" label="Process" required>
                                        <AvCheckbox label="Beginner" value="Beginner" />
                                        <AvCheckbox label="Intermediate" value="Intermediate" />
                                        <AvCheckbox label="Advanced" value="Advanced" />
                                    </AvCheckboxGroup>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="Amount" label="Amount" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="YourName" label="Your Name" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="YourAccountNo" label="Your Account No." required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="UTRReferenceNo" label="UTR/Reference No" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="file" type="file" label="Receipt/Screenshot of Payment" required />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group form-group-new">
                                        <AvField type="checkbox" name="avFieldCheckbox" required />
                                        <label>By registering you agree to the Terms of Use</label>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Submit
                                    </button>
                                </div>
                            </div>

                        </AvForm>
                        :
                        null

                }
                {
                    isConfirm == 2 ?
                        <AvForm method="POST" class=" contact-form contact-form">
                            <div class="row">
                                <h2 class="title mb-5">Gpay/Phone Pay</h2>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="payId" label="Gpay/Phone Pay ID" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="gpay" label="Gpay/phone Pay Name" required />
                                    </div>
                                </div>

                                <div class="col-lg-6">

                                    <AvCheckboxGroup inline name="checkboxExample2" label="Process" required>
                                        <AvCheckbox label="Beginner" value="Beginner" />
                                        <AvCheckbox label="Intermediate" value="Intermediate" />
                                        <AvCheckbox label="Advanced" value="Advanced" />
                                    </AvCheckboxGroup>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="Amount" label="Amount" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="ReferralCustID" label="Referral Cust ID" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="YourGpayPhonePayID" label="Your Gpay/Phone Pay ID" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="UTRReferenceNo" label="UTR/Reference No" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <AvField name="file" type="file" label="Receipt/Screenshot of Payment" required />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group form-group-new">
                                        <AvField type="checkbox" name="avFieldCheckbox" required />
                                        <label>By registering you agree to the Terms of Use</label>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Submit
                                    </button>
                                </div>
                            </div>

                        </AvForm>
                        :
                        null

                }
            </div>
        </div>
    )
}

