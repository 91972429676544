import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from '../helpers/helper_api';
import * as Utils from "./Utils";

const Witdraw = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [planId, setPlanId] = useState("");
    const [plan, setPlan] = useState(undefined);
    const [payble, setPayble] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState("Bank");
    const [methods, setMethods] = useState(undefined);
    const [wallet, setWallet] = useState("");
    const [token, setToken] = useState("");
    const [isGst, setIsGst] = useState(false);
    const [screen, setScreen] = useState("Method");
    const [amountPaid, setAmountPaid] = useState("0")

    useEffect(() => {
        setPlanId(params?.planId);
        setToken(params?.token);
        setWallet(params?.wallet);
    }, [params]);


    useEffect(() => {
        if (props?.setIsFooter)
            props?.setIsFooter(false);
    }, [props]);

    useEffect(() => {
        if (planId && token)
            getPlanDetails(planId);
    }, [planId, token]);

    const getPlanDetails = (id) => {
        get(`plan/detail?planId=${id}`, { token: token })
            .then(res => {
                if (res?.statusCode == 200) {
                    setPlan(res?.data);
                    let tempPlan = res?.data;
                    let tax = (Number(tempPlan?.amount) * Number(tempPlan?.gst)) / 100;
                    setPayble(String(Number(tempPlan?.amount) + (tax + Number(tempPlan?.hCharge))));
                }
            })
            .catch(err => {
                console.log("error while getting plan", err);
            });
        get("setting", { token: token })
            .then(res => {
                if (res?.statusCode == 200) {
                    setMethods(res?.data);
                }
            })
            .catch(err => {

            })
    }

    const handleSubmit = (e, v) => {
        if (screen == "Method") {
            setAmountPaid(v?.paid)
            setScreen("Detail");
            return
        }
        setLoading(true);
        console.log("calling api", v);
        let body = {
            ...v,
            balance: wallet,
            type: "Withdraw",
            status: "Requested",
            token: token
        }
        post("wallet/request", body)
            .then(res => {
                setLoading(false);
                console.log("requested", res?.user);
                if (res?.statusCode == 200) {
                    setIsGst(false);
                    navigate("/withdraw/success");
                } else {
                    navigate("/withdraw/failed");
                    // Toast.show({ type: 'error', text1: 'Error', text2: '' + res?.error });
                }
            })
            .catch(err => {
                navigate("/withdraw/failed");
                setLoading(false);
                console.log("error on user details", err);
                // Toast.show({ type: 'error', text1: 'Error', text2: 'Something Went Wrong!' });
            })
    }

    // console.log("mere consol", plan)

    return (
        <div>
            <div className='w-100'>
                <AvForm onValidSubmit={handleSubmit}>

                    {screen == "Method" ?
                        <>
                            <div className='amount w-100 p-2'>
                                <div className='w-100'>
                                    <p style={{ color: Utils.themeColor, fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>{plan?.title}</p>
                                    <p style={{ color: Utils.themeColor, marginBottom: 0 }}>{plan?.tagLine}</p>
                                    <p style={{ color: Utils.themeColor, fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>₹ {Number(plan?.amount).toLocaleString()}/-
                                        <span style={{ color: Utils.themeColor, fontSize: 14, fontWeight: 'normal', marginBottom: 0 }}> + {plan?.gst}% GST + ₹{plan?.hCharge} Handling Charges</span>
                                    </p>
                                    <p style={{ color: Utils.themeColor, fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>
                                        <span style={{ color: Utils.themeColor, fontSize: 14, fontWeight: 'normal', marginBottom: 0 }}>Payable Amount -</span> ₹ {Number(payble).toLocaleString()} /-
                                    </p>
                                </div>
                            </div>

                            <div className="mb-3 mx-2 mt-3">
                                <div className="mobile-input">
                                    <span className="input-group-text"> ₹ </span>
                                    <AvField
                                        name="paid"
                                        type="number"
                                        // id="basic-url"
                                        placeholder='Enter the amount your paid'
                                        required
                                    />
                                </div>
                            </div>
                            <div className='amount'>
                                <div>
                                    <p className='ms-1'>
                                        Amount should be greater than ₹ 5000 /-
                                    </p>
                                </div>
                            </div>
                            <div className="mb-3 mx-2 mt-3">
                                <div className="payment-options">
                                    <div className={`option ${selectedMethod == "Bank" ? 'selected' : ''}`} onClick={() => setSelectedMethod("Bank")}>
                                        {selectedMethod == "Bank" ?
                                            <span className="checkmark">✔</span>
                                            : null}
                                        <p>IMPS/NEFT/RTGS</p>
                                    </div>
                                    <div className={`option ${selectedMethod == "UPI" ? 'selected' : ''}`} onClick={() => setSelectedMethod("UPI")}>
                                        {selectedMethod == "UPI" ?
                                            <span className="checkmark">✔</span>
                                            : null}
                                        <p>Pay With UPI</p>
                                    </div>
                                </div>
                            </div>
                            {selectedMethod == "Bank" ?
                                <div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0 }}>
                                                <i className="fas fa-university" style={{ fontSize: 22, marginRight: 10 }}></i> Bank Name :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.bank?.bankName}</p>
                                        </div>
                                    </div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0 }}>
                                                <i className="fas fa-building" style={{ fontSize: 22, marginRight: 10 }}></i> Company Name :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.bank?.companyName}</p>
                                        </div>
                                    </div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0, marginLeft: 3 }}>
                                                <i className="fas fa-" style={{ fontSize: 22, marginRight: 16 }}>₹</i> Account No. :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.bank?.acNumber}</p>
                                        </div>
                                    </div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0 }}>
                                                <i className="fas fa-code" style={{ fontSize: 22, marginRight: 10 }}></i> IFSC Code :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.bank?.ifscCode}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                        <img src={methods?.upi?.image} style={{ width: 150, height: 150 }} />
                                    </div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0 }}>
                                                <i className="fas fa-user" style={{ fontSize: 22, marginRight: 10 }}></i> UPI Name :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.upi?.name}</p>
                                        </div>
                                    </div>
                                    <div className='payment-options w-75 mx-5 mt-4'>
                                        <div>
                                            <p style={{ color: Utils.themeColor, fontWeight: 'normal', marginBottom: 0 }}>
                                                <i className="fas fa-building" style={{ fontSize: 22, marginRight: 10 }}></i> UPI ID :
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: Utils.themeColorDark, fontWeight: 'normal', marginBottom: 0 }}>{methods?.upi?.upiId}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                            {selectedMethod == "Bank" ?
                                <>
                                    <div className="mb-4 mx-2 mt-3">
                                        <label htmlFor="name">Bank  Account  Holder Name *</label>
                                        <div className="mobile-input">
                                            <span className="input-group-text"><i className="fas fa-phone" style={{ fontSize: 20, }}></i></span>
                                            <AvField
                                                name="upinumber"
                                                type="number"
                                                // id="basic-url"
                                                placeholder='Enter Account holder Name'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 mx-2 mt-3">
                                        <label htmlFor="name">Bank Account Holder Number *</label>
                                        <div className="mobile-input">
                                            <span className="input-group-text"><i className="fas fa-caret-right" style={{ fontSize: 20, }}></i> </span>
                                            <AvField
                                                name="upiid"
                                                type="text"
                                                // label="UPI ID"
                                                // id="basic-url"
                                                placeholder='Enter Your Account Number'
                                                required
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="mb-4 mx-2 mt-3">
                                        <label htmlFor="name">UPI Number *</label>
                                        <div className="mobile-input">
                                            <span className="input-group-text"><i className="fas fa-phone" style={{ fontSize: 20, }}></i></span>
                                            <AvField
                                                name="upinumber"
                                                type="number"
                                                // id="basic-url"
                                                placeholder='Enter UPI Number'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 mx-2 mt-3">
                                        <label htmlFor="name">UPI Id *</label>
                                        <div className="mobile-input">
                                            <span className="input-group-text"> <i className="fas fa-caret-right" style={{ fontSize: 20, }}></i></span>
                                            <AvField
                                                name="upiid"
                                                type="text"
                                                // label="UPI ID"
                                                // id="basic-url"
                                                placeholder='Enter UPI Id'
                                                required
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="mb-4 mx-2 mt-3">
                                <label htmlFor="name">Amount Paid *</label>
                                <div className="mobile-input">
                                    <span className="input-group-text"> ₹ </span>
                                    <AvField
                                        name="amount"
                                        type="number"
                                        // id="basic-url"
                                        placeholder='Enter the amount your paid'
                                        required
                                        value={amountPaid}
                                        disabled

                                    />
                                </div>
                            </div>
                            <div className="mb-4 mx-2 mt-3">
                                <label htmlFor="name">Transaction Id *</label>
                                <div className="mobile-input">
                                    <span className="input-group-text"> <i className="fas fa-credit-card" style={{ fontSize: 20, }}></i></span>
                                    <AvField
                                        name="transcation"
                                        type="text"
                                        // id="basic-url"
                                        placeholder='Enter Transaction Id'
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-4 mx-2 mt-3">
                                <label htmlFor="name">Invite Code </label>
                                <div className="mobile-input">
                                    <span className="input-group-text"><i className="fas fa-code" style={{ fontSize: 20, }}></i></span>
                                    <AvField
                                        name="invite"
                                        type="number"
                                        // id="basic-url"
                                        placeholder='Enter Invite Code (Optional)'
                                    />
                                </div>
                            </div>
                            <div className="mb-4 mx-2 mt-3">
                                <label htmlFor="name"> Add Screenshot *</label>
                                <div className="mobile-input">
                                    <span className="input-group-text"><i className="fas fa-image" style={{ fontSize: 20, }}></i></span>
                                    <AvField
                                        name="screenshot"
                                        type="text"
                                        // id="basic-url"
                                        placeholder='Add Screenshot'
                                        required
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className='submit_box mb-4'>
                        <button type="submit" className="gst_btn">Yes I Have paid <i className="fas fa-arrow-right ms-2"></i></button>
                    </div>
                </AvForm>
            </div>
        </div >
    )
}

export default Witdraw;
