import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "Dashboard",
    breadcrumbItems: [
        { title: "Lexa", link: "#" },
        { title: "Dashboard", link: "#" },
        { title: "Dashboard", link: "#" },
    ],
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setBreadcrumbItems(state, action) {
            state.title = action.payload.title;
            state.breadcrumbItems = action.payload.items;
            console.log('state',state ,action)
        }
    }
});

export const { setBreadcrumbItems } = layoutSlice.actions;
export default layoutSlice.reducer;
