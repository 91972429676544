import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
   return (
      <footer class="bg-dark text-light" style={{ backgroundImage: 'url(assets/img/shape/5.png);' }}>

         <div class="footer-bottom bg-dark text-light text-center">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <ul className='footer-ul'>
                        <li><Link to='/terms-of-use'>Terms of use</Link></li>
                        <li>|</li>
                        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                        <li>|</li>
                        <li><a href='https://apex-leap.com/#contact'>Contact</a></li>
                        <li>|</li>
                        <li><Link to='/faq'>FAQ's</Link></li>
                     </ul>
                     <p>
                        Copyright &copy; 2024 Apex Leap. All Rights Reserved. Website by RAJMITH.
                     </p>
                  </div>
               </div>
            </div>
         </div>

      </footer>
   )
}
