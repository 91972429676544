import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { post } from '../helpers/helper_api'
import { toast } from 'react-toastify'

export default function ForgotPassword() {

    const [isOtp, setIsOtp] = useState(false)
    const [userId, setUserId] = useState(undefined)
    const formRef = useRef(null)
    const formRef1 = useRef(null)

    const handleValidSubmit = (e, v) => {
        let body = {
            ...v,
        }
        post('forgot_pass', body)
            .then((res) => {
                // console.log('res', res);
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    setIsOtp(true)
                    formRef?.current?.reset()
                }
                if (res?.statusCode == 401) {
                    toast.success(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.success(res?.error)
                }
            }).catch(err => {
                console.log('errr', err)
            })
    }
    const handleValidSubmitVerify = (e, v) => {

        let body = {
            otp: v?.otp,
            password: v?.password,
            userId
        }
        if (v?.password === v?.repassword) {
            post('verify_register', body)
                .then((res) => {
                    console.log('res', res);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                        formRef1?.current?.reset()
                    }
                    if (res?.statusCode == 400) {
                        toast.success(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.success(res?.error)
                    }
                }).catch(err => {
                    console.log('errr', err)
                })
        } else {
            toast.error('Please Match passward')
        }

    }


    return (
        <div>
            <div className='inner-banner'>

            </div>
            <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>

                <h2 class="title mb-5">Reset Password</h2>
                {
                    !isOtp ?

                        <AvForm
                            ref={formRef}
                            onValidSubmit={handleValidSubmit}
                            class="contact-form contact-form">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="userId" onChange={(e) => setUserId(e.target.value)} label="Cust Id" required />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Submit
                                    </button>
                                </div>
                            </div>
                            <div className='form-link mt-4'>

                                <p>Remember It ? <Link to={'/login'}> Sign In Here</Link></p>

                            </div>
                        </AvForm>
                        :
                        <AvForm
                            ref={formRef1}
                            onValidSubmit={handleValidSubmitVerify}
                            class="contact-form contact-form">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="otp" label="Otp" required />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="password" label="Password" required />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="repassword" label="Re Password" required />
                                    </div>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Submit
                                    </button>
                                </div>
                            </div>

                            <div className='form-link mt-4'>

                                <p>Remember It ? <Link to={'/login'}> Sign In Here</Link></p>

                            </div>

                        </AvForm>
                }

            </div>
        </div>
    )
}
