import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { get } from '../helpers/helper_api';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderInner from '../component/HeaderInner';
import moment from 'moment-timezone';

export default function MyProfile() {
    const navigator = useNavigate()
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(undefined)
    const [confirmModal, setConfirmModal] = useState(false);

    const toggle = () => setConfirmModal(!confirmModal);
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])
    const user = useSelector((state) => state.user.data);
    useEffect(() => {
        if (user?._id) {
            getData(user?._id)
        }
    }, [user])

    useEffect(() => {
        if (user)
            if (user?.step == 4) {
                navigator('/my-profile')
            } else {
                navigator('/register')
            }
    }, [user])


    const getData = (userId) => {
        setLoading(true)
        get(`user/detail?userId=${userId}`)
            .then((res) => {
                setLoading(false)
                if (res?.statusCode == 200) {
                    setData(res?.data)
                }
                // console.log('user res', res)

            }).catch(err => {
                setLoading(false)
                console.log('errer', err)
            })
    }


    // console.log('my profile',user)
    return (
        <div className='mt-5 mt_0_mobile'>
            <HeaderInner />
            <Modal centered isOpen={confirmModal} >
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div className='confirm-desc'>
                        <img src={image ? image : null} />
                    </div>
                </ModalBody>

            </Modal>
            <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>



                <div className='profile-section'>
                    {/* <div className='sort-detail'> */}
                    {/* <div className='userIcon'>
                           {
                            data?.name?.split(' ')[]
                           }
                        </div> */}
                    {/* <div className='user-detail-box'>
                            <h4><strong>Name</strong>{data?.name}</h4>
                            <h6><strong>Cust id</strong>{data?.userId}</h6>
                            <h6><strong>Email</strong>{data?.email}</h6>
                            <p><strong>Mobile</strong>{data?.mobile}</p>
                        </div> */}
                    <h2 class="title mb-5">My Account</h2>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='user-card-box-1'>
                                <div className='box-content'>
                                    <p>Name</p>
                                    <span>{data?.name}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Cust id</p>
                                    <span>{data?.userId}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Email</p>
                                    <span>{data?.email}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Mobile</p>
                                    <span>{data?.mobile}</span>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='user-card-box-1'>
                                <div className='box-content'>
                                    <p>Plan Name</p>
                                    <span>{data?.trans[0]?.title}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Paid Amount</p>
                                    <span>{data?.trans[0]?.paid}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Transaction Id</p>
                                    <span>{data?.trans[0]?.txnId}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Status</p>
                                    <span>{data?.trans[0]?.status}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Expiry</p>
                                    <span>{moment(data?.expiry).isAfter(moment()) ? moment(data?.expiry).format('MMM-DD-YYYY'):""}</span>
                                </div>
                                <div className='box-content'>
                                    <p>Receipt</p>
                                    <button onClick={() => { setImage(data?.trans[0]?.receipt); setConfirmModal(true) }}>View Image</button>
                                </div>

                            </div>
                        </div>


                    </div>
                    {/* </div> */}
                    <div className='user-details-box'>
                        <h2 class="title my-5">Personal Details</h2>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='user-card-box-1'>
                                    <div className='box-content'>
                                        <p>Name</p>
                                        <span>{data?.name}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>DOB</p>
                                        <span>{moment(data?.dob).format('MMM-DD-YYYY')}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Address</p>
                                        <span>{data?.address}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Pin Code</p>
                                        <span>{data?.pinCode}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>District</p>
                                        <span>{data?.district}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>State</p>
                                        <span>{data?.state}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='user-card-box-1'>

                                    <div className='box-content'>
                                        <p>Doc Type</p>
                                        <span>{data?.docType}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>{data?.docType} Number</p>
                                        <span>{data?.docNumber}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Doc Image</p>
                                        <button onClick={() => { setImage(data?.docImage); setConfirmModal(true) }}>View Image</button>
                                    </div>
                                    <div className='box-content'>
                                        <p>Pan Number</p>
                                        <span>{data?.panNumber}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Pan Card Image</p>
                                        <button onClick={() => { setImage(data?.panImage); setConfirmModal(true) }}>View Image</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='user-details-box'>
                        <h2 class="title my-5">Bank Details</h2>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='user-card-box-1'>
                                    <div className='box-content'>
                                        <p>Account Holder Name</p>
                                        <span>{data?.acHolder}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Account Number</p>
                                        <span>{data?.acNumber}</span>
                                    </div>

                                    <div className='box-content'>
                                        <p>IFSC Code</p>
                                        <span>{data?.ifscCode}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Bank Name</p>
                                        <span>{data?.bankName}</span>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='user-card-box-1'>


                                    <div className='box-content'>
                                        <p>Branch</p>
                                        <span>{data?.branch}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Gpay/Phone Pay ID</p>
                                        <span>{data?.upiId}</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Gpay/Phone Pay Number</p>
                                        <span>{data?.upiNumber}</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
