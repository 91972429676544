import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from '../helpers/helper_api';


const Witdraw = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState("");
    const [wallet, setWallet] = useState("");
    const [token, setToken] = useState("");
    const [isGst, setIsGst] = useState(false);
    const [isPlanBalance, setIsPlanBalance] = useState(false);
    const [plan, setPlan] = useState(null);

    useEffect(() => {
        if (props?.setIsFooter)
            props?.setIsFooter(false);
    }, [props])

    const getCharge = () => {
        let total = Number(amount);
        let tax = (total * 10) / 100;
        return tax;
    }

    const getGst = () => {
        let total = Number(amount) - getCharge();
        let tax = total / 118 * 100;
        return total - tax;
    }

    const getWithdraw = () => {
        let total = Number(amount);
        let tax = getCharge();
        // console.log("charge", tax);
        let afterCharge = total - tax;
        // console.log("after charge", afterCharge);
        let gst = getGst();
        // console.log("gst", gst);
        // console.log("after gst", afterCharge - gst);
        return afterCharge - gst;
    }

    useEffect(() => {
        setAmount(params?.amount);
        setToken(params?.token);
    }, [params]);

    useEffect(() => {
        if (token)
            getPlan();
    }, [token]);

    const getPlan = () => {
        get("user/plan", { token: token })
            .then(res => {
                console.log('response from user plan', res);
                if (res?.statusCode == 200) {
                    if (res?.data)
                        setPlan(res?.data);
                    if (res?.data?.due > 0)
                        setIsPlanBalance(true);
                }
            })
    }

    const handleWithdraw = (e, v) => {
        setLoading(true);
        console.log("calling api", v);
        let body = {
            ...v,
            amount: amount,
            type: "Withdraw",
            status: "Requested",
            token: token
        }
        if (isPlanBalance) {
            body = { ...body, planDeduct: getWithdraw() * 0.6, planBalance: plan?.due - getWithdraw() * 0.6 }
        }
        post("wallet/request", body)
            .then(res => {
                setLoading(false);
                console.log("requested", res?.user);
                if (res?.statusCode == 200) {
                    setIsGst(false);
                    navigate("/withdraw/success");
                } else {
                    navigate("/withdraw/failed");
                    // Toast.show({ type: 'error', text1: 'Error', text2: '' + res?.error });
                }
            })
            .catch(err => {
                navigate("/withdraw/failed");
                setLoading(false);
                console.log("error on user details", err);
                // Toast.show({ type: 'error', text1: 'Error', text2: 'Something Went Wrong!' });
            })
    }

    // console.log("mere consol", token);

    return (
        <div>
            <div className='widrw'>

                <div className='amount'>
                    <div className='left_sew'>
                        <p>
                            Amount Requested
                        </p>
                    </div>
                    <div className='right_sew'>
                        <p>{amount}</p>
                    </div>
                </div>


                <div className='amount'>
                    <div className='left_sew'>
                        <p>
                            Service Charge (10%)
                        </p>
                    </div>
                    <div className=''>
                        <p >-{getCharge()}</p>
                    </div>
                </div>

                <div className='amount'>
                    <div className='left_sew'>
                        <p className=''>
                            With GST
                        </p>
                    </div>
                    <div className='with_box'>
                        <p>{amount - getCharge()}</p>
                    </div>
                </div>

                <div className='amount'>
                    <div className='left_sew'>
                        <p>
                            GST (18%)
                        </p>
                    </div>
                    <div>
                        <p>-{getGst().toFixed(2)}</p>
                    </div>
                </div>

                <div className='amount'>
                    <div className='left_sew'>
                        <p>
                            Widthrawable Amount
                        </p>
                    </div>
                    <div>
                        <p>₹{getWithdraw().toFixed(2)}</p>
                    </div>
                </div>


                {/* ---------------------------------------------------------------------------------- */}
                {isPlanBalance ?
                    <div>
                        <div className='amount'>
                            <div className='left_sew'>
                                <p>Plan</p>
                            </div>
                            <div>
                                <p>{plan?.title} ( ₹{plan?.amount.toFixed(2)})</p>
                            </div>
                        </div>

                        <div className='amount'>
                            <div className='left_sew'>
                                <p>Plan Due</p>
                            </div>
                            <div>
                                <p>₹{plan?.due.toFixed(2)}</p>
                            </div>
                        </div>


                        <div className='amount'>
                            <div className='left_sew'>
                                <p>
                                    Deduction (60%)
                                </p>
                            </div>
                            <div>
                                <p>₹{(getWithdraw() * 0.6).toFixed(2)}</p>
                            </div>
                        </div>


                        <div className='amount'>
                            <div className='left_sew'>
                                <p>
                                    Receive(40%)
                                </p>
                            </div>
                            <div>
                                <p>₹{(getWithdraw() - (getWithdraw() * 0.6)).toFixed(2)}</p>
                            </div>
                        </div>
                    </div> : null}


                <AvForm onValidSubmit={handleWithdraw}>
                    <div className='ms-5 mb-4 mt-3'>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setIsGst(!isGst)} />
                            <label class="form-check-label" for="defaultCheck1">
                                Yes, I Have GST Details
                            </label>
                        </div>
                    </div>
                    {isGst ?
                        <>
                            <div class=" form_detil">
                                <label for="basic-url" class="form-label">Company Name *</label>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon3"> <i class="fas fa-building bulding"></i> </span>
                                    <AvField style={{ width: "115%" }}
                                        name="companyName"
                                        type="text"
                                        // class="form-control"
                                        // id="basic-url"
                                        placeholder='Enter Company Name'
                                        required
                                    />
                                </div>
                            </div>

                            <div class=" form_detil">
                                <label for="basic-url" class="form-label">GST Number</label>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon3"> %</span>
                                    <AvField style={{ width: "115%" }}
                                        name="gstNo"
                                        type="text"
                                        // class="form-control" 
                                        // id="basic-url" 
                                        placeholder='Enter GST Number'
                                        required
                                    />
                                </div>
                            </div>
                        </>
                        : null}
                    <div className='submit_box'>
                        <button type="submit" class="gst_btn">SUBMIT <i class="fas fa-arrow-right ms-2"></i> </button>
                    </div>
                </AvForm>
            </div>

        </div>
    )
}

export default Witdraw;
