import logo from './logo.svg';
import './assets/css/style.css';

import Router from './route/Router';
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
