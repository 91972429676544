import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post } from '../helpers/helper_api'
import { toast } from 'react-toastify'
import Loader from '../component/Loader'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setUser } from '../store/user'
import { useDispatch } from 'react-redux'
import HeaderInner from '../component/HeaderInner'
export default function Login() {

    const navigator = useNavigate();
    const [loading, setLoading] = useState(false)
    const [custModal, setCustModal] = useState(false);
    const [forgetPassModal, setForgetPassModal] = useState(false);
    const [isOtp, setIsOtp] = useState(false)
    const [userId, setUserId] = useState(undefined)

    const toggle = () => setCustModal(!custModal);
    const togglePass = () => setForgetPassModal(!forgetPassModal);
    const formRef = useRef(null)
    const formRef1 = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleValidSubmit = (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            deviceType: 'web',
            deviceToken: 'abc'
        }
        post('login', body)
            .then((res) => {
                console.log('res', res);
                setLoading(false)
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    localStorage.setItem("user", JSON.stringify(res?.data));
                    localStorage.setItem("userId", res?.data?.userId);
                    dispatch(setUser(res?.data));

                    if (res?.data?.step == 1) {
                        localStorage.setItem('step', 3);
                        navigator('/register')
                    }
                    if (res?.data?.step == 2) {
                        localStorage.setItem('step', 4);
                        navigator('/register')
                    }
                    if (res?.data?.step == 3) {
                        localStorage.setItem('step', 6);
                        navigator('/register')
                    }

                    if (res?.data?.step == 4) {
                        navigator('/my-profile')
                    } else {
                        navigator('/register')
                    }
                    // navigator('/')
                }
                if (res?.statusCode == 401) {
                    toast.error(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.error(res?.error)
                }
            }).catch(err => {
                setLoading(false)
                console.log('errr', err)
                toast.error("Something Went Wrong!")
            })
    }
    const handleValidSubmitCust = (e, v) => {

        let body = {
            ...v,
        }
        post('forgot_cust', body)
            .then((res) => {
                // console.log('res', res);
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    setCustModal(false)
                }
                if (res?.statusCode == 401) {
                    toast.success(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.success(res?.error)
                }
            }).catch(err => {
                console.log('errr', err)
            })
    }

    const handleValidSubmitPass = (e, v) => {
        let body = {
            ...v,
        }
        post('forgot_pass', body)
            .then((res) => {
                // console.log('res', res);
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    setIsOtp(true)
                    formRef?.current?.reset()
                }
                if (res?.statusCode == 401) {
                    toast.success(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.success(res?.error)
                }
            }).catch(err => {
                console.log('errr', err)
            })
    }
    const handleValidSubmitVerify = (e, v) => {

        let body = {
            otp: v?.otp,
            password: v?.password,
            userId
        }
        if (v?.password === v?.repassword) {
            post('verify_register', body)
                .then((res) => {
                    console.log('res', res);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                        formRef1?.current?.reset()
                    }
                    if (res?.statusCode == 400) {
                        toast.success(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.success(res?.error)
                    }
                }).catch(err => {
                    console.log('errr', err)
                })
        } else {
            toast.error('Please Match passward')
        }

    }


    return (
        <div>
            {
                loading ? <Loader /> : null
            }

            <HeaderInner />

            <Modal centered isOpen={custModal} >
                <ModalHeader toggle={toggle}>Forget Cust ID</ModalHeader>
                <ModalBody>
                    <AvForm

                        onValidSubmit={handleValidSubmitCust}
                        class="contact-form contact-form">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <AvField name="email" label="Email" required />
                                </div>
                            </div>

                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-12">
                                <button type="submit">
                                    <i class="fa fa-paper-plane"></i> Submit
                                </button>
                            </div>
                        </div>


                    </AvForm>
                </ModalBody>

            </Modal>
            <Modal centered isOpen={forgetPassModal} >
                <ModalHeader toggle={togglePass}>Reset Password</ModalHeader>
                <ModalBody>
                    {
                        !isOtp ?

                            <AvForm
                                ref={formRef}
                                onValidSubmit={handleValidSubmitPass}
                                class="contact-form contact-form">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <AvField name="userId" onChange={(e) => setUserId(e.target.value)} label="Cust Id" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-12">
                                        <button type="submit" name="submit" id="submit">
                                            <i class="fa fa-paper-plane"></i> Submit
                                        </button>
                                    </div>
                                </div>

                            </AvForm>
                            :
                            <AvForm
                                ref={formRef1}
                                onValidSubmit={handleValidSubmitVerify}
                                class="contact-form contact-form">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <AvField name="otp" label="Otp" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <AvField name="password" label="Password" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <AvField name="repassword" label="Re Password" required />
                                        </div>
                                    </div>


                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-12">
                                        <button type="submit" name="submit" id="submit">
                                            <i class="fa fa-paper-plane"></i> Submit
                                        </button>
                                    </div>
                                </div>



                            </AvForm>
                    }
                </ModalBody>

            </Modal>


            <div class="contact-form-style-one mt-100px auth-section p-0" style={{ boxShadow: 'none' }}>


                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='left-section'>
                            <div className='header-title'>
                                <h4 className=''>Welcome Back to Apex Leap</h4>
                                <h2 class="title">Log In to Continue Your Journey</h2>

                            </div>
                            <AvForm
                                onValidSubmit={handleValidSubmit}
                                class="contact-form contact-form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="userId" label="Cust Id" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="password" label="Password" required />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <button type="submit" name="submit" id="submit">
                                            <i class="fa fa-paper-plane"></i> Login
                                        </button>
                                    </div>
                                </div>

                                <div className='form-link mt-4'>
                                    <a href='javascript:void(0);' onClick={() => setCustModal(true)}>Forgot Cust ID</a>
                                    |
                                    <a href='javascript:void(0);' onClick={() => setForgetPassModal(true)}>Forgot your password?</a>
                                    |
                                    <p>New?   <Link to={'/register'}>Registration</Link></p>

                                </div>


                            </AvForm>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='right-section'>
                            <img src={require('../assets/img/login.jpg')} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}


